import React, { useState, useEffect, useRef } from 'react';
import { FileUploader } from "react-drag-drop-files";
import prettyBytes from 'pretty-bytes';
import i18nStringsFiles from '@l10nmonster/i18n-strings-files';
import { encode } from 'gpt-tokenizer/model/gpt-4';
import { countWords } from "alfaaz";
import Select from 'react-select';
import Modal from 'react-modal';
import BeatLoader from "react-spinners/BeatLoader";
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { XCStrings } from 'xcstrings';
import languages from '../../languages';

Modal.setAppElement('#root');

const fileTypes = ["strings", "stringsdict", "xcstrings", "txt"];

const IconSoftwareUpload = (props) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" height="2em" width="2em" {...props}>
      <path
        fill="currentColor"
        d="M11 14.986a1 1 0 102 0V7.828l3.243 3.243 1.414-1.414L12 4 6.343 9.657l1.414 1.414L11 7.83v7.157z"
      />
      <path
        fill="currentColor"
        d="M4 14h2v4h12v-4h2v4a2 2 0 01-2 2H6a2 2 0 01-2-2v-4z"
      />
    </svg>
  );
};

function IconClose(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
    </svg>
  );
}

function IconInfoCircled(props) {
  return (
    <svg fill="none" viewBox="0 0 15 15" height="1.5em" width="1.5em" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.5.877a6.623 6.623 0 100 13.246A6.623 6.623 0 007.5.877zM1.827 7.5a5.673 5.673 0 1111.346 0 5.673 5.673 0 01-11.346 0zm6.423-3a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6 6h1.5a.5.5 0 01.5.5V10h1v1H6v-1h1V7H6V6z"
        clipRule="evenodd"
      />
    </svg>
  );
}

const computeCost = (tokenCount, model = 'gpt-3') => {
  if (model === 'gpt-4') {
    return (tokenCount / 1000) * 0.2;
  }
  return (tokenCount / 1000) * 0.02;
};

const isString = (value) => {
  return typeof value === 'string' || value instanceof String;
};

const checkFileLength = (file) => {
  if (file.size > 1000000) {
    return false;
  }
};

const checkFileContentLength = async (file) => {
  const inputString = await file.text();
  const lines = inputString.split(/\n/);
  const maxWordsPerPart = 750;
  for (const line of lines) {
    const words = countWords(line);
    if (words > maxWordsPerPart) {
      return false;
    }
  }
  return true;
};

const computeCounts = async (file, languages) => {
  console.log('computeCounts', file, languages);
  const text = await file.text();
  var textToUse = text || '';
  if (isTypeOfFile(file.name, "xcstrings")) {
    var stringsFile = '';
    const xcstrings = new XCStrings(textToUse);
    const strings = xcstrings.convertToStrings({skipTranslated: true});
    console.log('strings', strings);
    console.log('languages', languages);
    if (Array.isArray(languages)) { 
      for (const language of languages) {
        console.log('language', language.value);
        const langStrings = strings['strings'][language.value];
        console.log('langStrings', langStrings ? langStrings.length : 0);
        if (langStrings === undefined) {
          stringsFile += strings['strings'][xcstrings.sourceLanguage];
        }
        else {
          stringsFile += langStrings;
        }
        console.log('stringsFile', stringsFile ? stringsFile.length : 0);
      }
    }
    textToUse = stringsFile;
  }
  var wordCount = 0;
  var tokenCount = 0;
  if (isTypeOfFile(file.name, "strings") || isTypeOfFile(file.name, "xcstrings")) {
    const strings = i18nStringsFiles.parse(textToUse || '');
    if (strings instanceof Object) {
      const values = Object.values(strings);
      //console.log('values', values);
      if (values.length > 0) {
        console.log('values.length', values.length);
        const wordCountOfValues = values.reduce((acc, string) => {
          const numWords = countWords(string);
          if (isString(acc)) {
            return string.split(acc).length + numWords;
          }
          else {
            return acc + numWords;
          }
        });
        if (!isString(wordCountOfValues)) {
          wordCount = wordCountOfValues;
        }
      }
    }
    wordCount *= languages.length;
    tokenCount = encode(textToUse.replaceAll('\n', '\\n')).length * 2;
    if (!isTypeOfFile(file.name, "xcstrings")) {
      tokenCount *= languages.length;
    }
  }
  else {
    wordCount = countWords(textToUse) * languages.length;
    console.log('wordCount', wordCount);
    tokenCount = encode(textToUse.replaceAll('\n', '\\n')).length * languages.length;
  }
  console.log('tokenCount', tokenCount);
  return {wordCount, tokenCount};
};

const isTypeOfFile = (filename, type) => {
  if (filename.toLowerCase().endsWith('.' + type)) {
    return true;
  }
  return false;
};

const CheckoutForm = ({price, email, sendDataToServer}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitButton = document.getElementById('submit');
    submitButton.disabled = true;
    setLoading(true);

    if (elements == null) {
      submitButton.disabled = false;
      setLoading(false);
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      console.error('submit error', submitError.message);
      setErrorMessage(submitError.message);
      submitButton.disabled = false;
      setLoading(false);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const {
      client_secret: clientSecret,
      message,
      success,
    } = await sendDataToServer();

    if (!success) {
      console.error('data error', message);
      setErrorMessage(message);
      submitButton.disabled = false;
      setLoading(false);
      return;
    }

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.protocol + '//' + window.location.host + process.env.REACT_APP_RETURN_PREFIX + '/return',
        receipt_email: email,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.error(error);
      setErrorMessage(error.message);
      submitButton.disabled = false;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button id="submit" type="submit" disabled={!stripe || !elements} className="bg-blue-500 hover:bg-blue-700 disabled:bg-blue-100 text-white font-bold py-2 px-4 rounded-full my-4">
        Pay ${price}
      </button>
      <BeatLoader
        className="ml-4"
        color={"blue"}
        loading={loading}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const FileListing = ({ file, languages }) => {
  console.log('FileListing', file, languages);
  const [wordCount, setWordCount] = useState(null);
  const [tokenCount, setTokenCount] = useState(null);
  useEffect(() => {
    console.log('useEffect');
    async function getCosts() {
        const {wordCount, tokenCount} = await computeCounts(file, languages);
        setWordCount(wordCount);
        setTokenCount(tokenCount);
    }
    getCosts();
  }, []);
  const bytes = prettyBytes(file.size);
  return (
    <tbody>
      <tr>
        <td>{file.name}</td>
        <td className="text-right">{bytes}</td>
        <td className="text-right">{wordCount} words</td>
        <td className="text-right">{tokenCount} tokens</td>
      </tr>
    </tbody>
  );
};

const FileListings = ({ files, languages }) => {
  console.log('FileListings files ', files);
  console.log('FileListings languages ', languages);
  files = files || [];
  return (
    <table className="table-auto border-spacing-x-3 border-separate">
      {Array.from(files).map((file, index) => {
        const key = `${file.name}-${index}-${languages.length || 0}`;
        console.log('FileListings key', key);
        return (
          <FileListing key={key} file={file} languages={languages} />
        )
    })}
    </table>
  );
};

const DragDropInside = ({ files, clearFiles, languages }) => {
  console.log('files', files);
  const clearAction = (event) => {
    event.preventDefault();
    event.stopPropagation();
    clearFiles();
    return false;
  };
  return (
    <div className="outline-dashed w-full h-full flex flex-col items-center justify-center bg-white">
      <FileListings files={files} languages={languages} />
      <button className="flex flex-row items-center mt-1">
        <IconSoftwareUpload/>
        Drop files to translate here<br/>
      </button>
      
     {files && files.length > 0 ? (<button className="flex flex-row items-center mt-1" onClick={clearAction}><IconClose/>Clear All</button>) : (<></>)}
    </div>
  );
};

const TranslationForm = () => {

  const minPrice = 0.99;
  var stripeOptions = {
    mode: 'payment',
    currency: 'usd',
    amount: minPrice * 100,
  };
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
  };

  const modelChoices = [
    { value: 'gpt-3', label: 'GPT-3.5' },
    { value: 'gpt-4', label: 'GPT-4' },
  ];

  const [files, setFiles] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [languagesDisabled, setLanguagesDisabled] = useState(false);
  const [modelDisabled, setModelDisabled] = useState(false);
  const [hasXcstrings, setHasXcstrings] = useState(false);

  var modelRef = useRef(selectedModel || modelChoices[0]);
  var languagesRef = useRef(selectedLanguages);

  if (!selectedLanguages) {
    const langs = localStorage.getItem('selectedLanguages');
    if (langs) {
      const slangs = JSON.parse(langs);
      setSelectedLanguages(slangs);
      languagesRef.current = slangs;
    }
  }

  if (!selectedModel) {
    const model = localStorage.getItem('selectedModel');
    if (model) {
      const parsedModel = JSON.parse(model);
      setSelectedModel(parsedModel);
      modelRef.current = parsedModel;
    }
  }

  if (!email) {
    const emailAddr = localStorage.getItem('email');
    if (emailAddr) {
      setEmail(emailAddr);
    }
  }

  console.log('modelRef.current', modelRef.current);
  console.log('languagesRef.current', languagesRef.current);

  const formattedFinalPrice = (price) => {
    return (Math.max(price, minPrice)).toFixed(2);
  }

  const formattedCalculatedPrice = (price) => {
    return (price || 0).toFixed(2);
  }

  const formattedPrice = (price) => {
    if (price < minPrice) {
      return formattedFinalPrice(price) + " (minimum) [$" + formattedCalculatedPrice(price) + " calculated]";
    }
    return formattedFinalPrice(price);
  };

  const languageByCode = (code) => {
    return languages.find((lang) => lang.value === code);
  };

  const extractLanguagesFromXcstrings = async (file) => {
    const text = await file.text();
    const xcstrings = new XCStrings(text);
    const languages = xcstrings.targetLanguages();
    console.log('extracted languages', languages);
    return languages;
  };

  const computeTotalCost = async (allFiles, allLanguages, model) => {
    console.log('computeTotalCost', model);
    if (!allFiles) {
      console.log('no files');
      return;
    }
    if (!allLanguages) {
      console.log('no languages');
      return;
    }
    var sumTokens = 0;
    var cost = 0;
    for (const file of allFiles) {
      const { wordCount, tokenCount } = await computeCounts(file, allLanguages);
      console.log('tokenCount', tokenCount);
      cost += computeCost(tokenCount, model.value);
      sumTokens += tokenCount;
    };
    console.log('sumTokens', sumTokens);
    console.log('allLanguages', allLanguages);
    console.log('computed cost', cost);
    setTotalCost(cost);
    stripeOptions.amount = cost * 100;
  };

  const handleFileChange = async (fileList) => {
    var currentFiles = files || [];
    const currentFilesNames = new Set(currentFiles.map((file) => file.name));
    for (const filename of currentFilesNames) {
      if (isTypeOfFile(filename, 'xcstrings')) {
        setHasXcstrings(true);
        break;
      }
    }
    var errorMessages = [];
    for (const file of fileList) {
      if (checkFileLength(file) === false) {
        errorMessages.push(`${file.name} too big. Only files up to 1MB are accepted currently.`);
        continue;
      }
      if (await checkFileContentLength(file) === false) {
        errorMessages.push(`${file.name} has at least one line that is too long to translate. Please split up the file into shorter lines.`);
        continue;
      }
      if (!currentFilesNames.has(file.name)) {
        currentFiles.push(file);
      }
    }
    console.log('currentFiles', currentFiles);
    if (errorMessages.length > 0) {
      console.log('errorMessages', errorMessages);
      alert(errorMessages.join('\n'));
    }
    var localHasXcstrings = hasXcstrings;
    if (!localHasXcstrings) {
      for (const file of currentFiles) {
        if (isTypeOfFile(file.name, 'xcstrings')) {
          setHasXcstrings(true);
          localHasXcstrings = true;
          break;
        }
      }
    }
    if (localHasXcstrings) {
      var langs = {};
      for (const file of currentFiles) {
        if (isTypeOfFile(file.name, 'xcstrings')) {
          const fileLangs = await extractLanguagesFromXcstrings(file);
          for (const lang of fileLangs) {
            langs[lang] = true;
          }
        }
      }
      var languages = Object.keys(langs);
      console.log('languages', languages);
      if (Array.isArray(languagesRef.current)) {
        for (const language of languagesRef.current) {
          if (!languages.includes(language.value)) {
            languages.push(language.value);
          }
        }
      }
      const newlySelectedLanguages = languages.map((language) => languageByCode(language));
      setSelectedLanguages(newlySelectedLanguages);
      languagesRef.current = newlySelectedLanguages;
    }
    setFiles(currentFiles);
    console.log('modelRef.current', modelRef.current);
    await computeTotalCost(currentFiles, languagesRef.current, modelRef.current);
  };

  const clearFiles = () => {
    setFiles(null);
    setTotalCost(0);
    setHasXcstrings(false);
  };

  const handleLanguageChange = async (selectedLanguages) => {
    setSelectedLanguages(selectedLanguages);
    languagesRef.current = selectedLanguages;
    localStorage.setItem('selectedLanguages', JSON.stringify(selectedLanguages));
    console.log(' handleLanguageChange files', files);
    await computeTotalCost(files, selectedLanguages, modelRef.current);
  };

  const handleModelChange = async (model) => {
    setSelectedModel(model);
    modelRef.current = model;
    localStorage.setItem('selectedModel', JSON.stringify(model));
    await computeTotalCost(files, languagesRef.current, model);
  };

  const handleEmailChange = async (event) => {
    console.log('email', event.target.value);
    localStorage.setItem('email', event.target.value);
    setEmail(event.target.value);
  };

  const handleProjectNameChange = async (event) => {
    console.log('projectName', event.target.value);
    setProjectName(event.target.value);
  };

  const wrongFile = (err) => {
    console.error('err', err);
    alert('Wrong file type. Only these file types are accepted currently: ' + fileTypes.join(', '));
  };

  const tooBig = (err) => {
    console.error('err', err);
    alert('File too big. Only files up to 1MB are accepted currently.');
  };

  const isValidEmail = (emailAddress) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(emailAddress);
  }

  const openModal = () => {
    if (!selectedLanguages || selectedLanguages.length === 0) {
      alert('Please select languages');
      return;
    }
    if (!files || files.length === 0) {
      alert('Please select files to translate');
      return;
    }
    if (!email || email.length === 0 || !isValidEmail(email)) {
      alert('Please enter a valid email address');
      return;
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const sendDataToServer = async () => {
    const url = `${process.env.REACT_APP_API_PREFIX}/submit`;
    var filesToUse = files || [];
    const fileNames = Array.from(filesToUse).map((file) => file.name);
    const filePromises = Array.from(filesToUse).map(async (file) => await file.text());
    const fileContents = await Promise.all(filePromises);
    console.log('fileNames', fileNames);
    var filesToSend = [];
    if (Array.isArray(fileNames) && Array.isArray(fileContents) && fileNames.length === fileContents.length) {
      for (var i = 0; i < fileNames.length; i++) {
        filesToSend.push({
          name: fileNames[i],
          content: fileContents[i]
        });
      }
    }
    const langs = languagesRef.current || selectedLanguages || [];
    var body = {
      files: filesToSend,
      email,
      amount: totalCost,
      name: projectName,
      languages: langs.map((lang) => lang.value),
      model: modelRef.current || selectedModel || modelChoices[0]
    };
    console.log('body', body);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const data = await response.json();
      console.log('data', data);
      return data;
    }
    catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.message
      };
    }
  };

  return (
    <>
      <div className="w-full h-1/2 p-8 mt-4">
        <FileUploader 
          handleChange={handleFileChange} 
          disabled={uploadDisabled} 
          onTypeError={wrongFile} 
          onSizeError={tooBig} 
          name="files" 
          types={fileTypes} 
          multiple={true} 
          maxSize={1} 
          children={<DragDropInside files={files} languages={selectedLanguages} clearFiles={clearFiles} />}
        />
      </div>
      <div className="w-full h-1/2">
        <Select
          options={languages}
          defaultValue={languagesRef.current}
          value={languagesRef.current}
          onChange={handleLanguageChange}
          isMulti={true}
          isDisabled={languagesDisabled}
          placeholder={"Select Languages"}
          hideSelectedOptions={languagesDisabled}
          isSearchable={true}
          className={languagesDisabled ? "mb-3 invisible" : "mb-3"}
        />
        <Select 
          options={modelChoices}
          defaultValue={modelRef.current} 
          value={modelRef.current}
          onChange={handleModelChange}
          isMulti={false} 
          isDisabled={modelDisabled}
          placeholder="Select Model"
          isSearchable={false}
          className="mb-3"
        />
        <input 
          type="text"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email Address (results will be sent here)" 
          className="mx-[2px] mb-3 box-border w-full p-2"
        />
        <input 
          type="text" 
          onChange={handleProjectNameChange}
          placeholder="Project name (optional)" 
          className="mx-[2px] mb-3 box-border w-full p-2"
        />
        <div className="mt-2">
          <button onClick={openModal} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-4 rounded-full">Buy</button>
          Total Cost: ${formattedPrice(totalCost)}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="Purchase Translation"
          >
            <Elements stripe={stripePromise} options={stripeOptions}>
              <CheckoutForm price={formattedFinalPrice(totalCost)} email={email} sendDataToServer={sendDataToServer} />
            </Elements>
          </Modal>
        </div>
      </div>
    </>
  );
};

const FrontPage = ({ title }) => {

  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);

  const infoModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '50%',
      maxWidth: '500px',
      height: '50%',
      maxHeight: '350px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
  };
  const openInfoModal = () => {
    setInfoModalIsOpen(true);
  };
  const closeInfoModal = () => {
    setInfoModalIsOpen(false);
  };

  return (
    <main className="w-full h-full p-4">
      <div>
        <h1 className="float-left text-2xl">{title}</h1>
        <div className="float-right" onClick={openInfoModal}>
          <IconInfoCircled />
        </div>
      </div>
      <Modal
        isOpen={infoModalIsOpen}
        onRequestClose={closeInfoModal}
        style={infoModalStyles}
        contentLabel="Info"
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl">Info</h2>
        </div>
        <div className="flex flex-col">
          <p className="p-2">
            Cromulent AI Translation is a service that uses OpenAI's LLMs (GPT-3.5 and GPT-4) to generate high quality translations for a one-time fee.
          </p>
          <p className="p-2">
            Max File Size: 1MB
          </p>
          <p className="p-2">
            Supported file types: {fileTypes.join(', ')}
          </p>
          <p className="p-2">
            Questions: <a className="underline decoration-sky-600" href="mailto:support@cromulentlabs.com">support@cromulentlabs.com</a>
          </p>
        </div>
      </Modal>
      <TranslationForm />
    </main>
  );
};

export default FrontPage;
