import React, { Component } from 'react';
import 'tailwindcss/tailwind.css';
import _ from 'lodash';
import FrontPage from './components/main/layout';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

class Return extends Component {

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    console.log('componentDidMount');
    const params = new URLSearchParams(window.location.search);
    const status = params.get('redirect_status');
    if (status === 'succeeded') {
      const id = params.get('payment_intent');
      const url = `${process.env.REACT_APP_API_PREFIX}/success`;
      const body = {
        id
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const data = await response.json();
      console.log('data', data);
    }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
  }

  render() {
    const params = new URLSearchParams(window.location.search);
    const status = params.get('redirect_status');
    if (status === 'succeeded') {
      return (
        <section id="success" className="p-8 flex flex-col items-center justify-center">
          <p className="p-8">
            Thank you for your order! When your translation is completed it will be emailed to you, usually within an hour.
          </p>
          <p className="p-8">
            If you have any questions, please email <a href="mailto:support@cromulentlabs.com" className="underline decoration-sky-600">support@cromulentlabs.com</a>.
          </p>
          <p className="p-8">
            <a className="underline decoration-sky-600" href="javascript:history.back()">Go Back</a>
          </p>
        </section>
      )
    }
    else {
      return (
        <section id="error" className="p-8 flex flex-col items-center justify-center">
          <p className="p-8">
            I'm sorry, there was an error trying to process the payment. Please go back and try again.
          </p>
          <p className="p-8">
            If this keeps happening, please email <a href="mailto:support@cromulentlabs.com" className="underline decoration-sky-600">support@cromulentlabs.com</a>.
          </p>
          <p className="p-8">
            <a className="underline decoration-sky-600" href="javascript:history.back()">Go Back</a>
          </p>
        </section>
      )
    }
  }
}

class App extends Component {

  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.state = {
      queryParams: params
    };
  }

  componentDidMount () {
    console.log('componentDidMount');
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
  }

  render() {
    return ( 
      <ErrorBoundary>
        <div className="bg-gray-100 dark:bg-slate-800 w-screen h-screen">
          <Router>
            <Routes>
              <Route path="/" element={ <FrontPage title="Cromulent AI Translation"/>} />
              <Route path="/return" element={<Return />} />
              <Route path="/dev" element={ <FrontPage title="Cromulent AI Translation"/>} />
              <Route path="/dev/return" element={<Return />} />
            </Routes>
          </Router>
        </div>
      </ErrorBoundary>
    );
  }
}

export default App;
